import { core } from 'novapay-ui';

import commonStore from '@services/common-store';

const types = core.createTypes('profile');
const { mutations } = commonStore('profile');

export default {
	...mutations,
	[types.GET_PROFILE_SUCCESS]: (state, profile) => {
		state.props = { ...state.props, profile };
	},
	[types.GET_DOCUMENTS_SUCCESS]: (state, { documents, query }) => {
		state.props = { ...state.props, documents, documents_query: query };
	},
	[types.DEPUTY_ID_REQUIRED]: (state, deputy_id_required = true) => {
		state.props = { ...state.props, deputy_id_required };
	},
	[types.GET_SALEPOINT_DOCUMENTS_SUCCESS]: (state, { documents, query }) => {
		state.props = { ...state.props, salepoint_documents: documents, salepoint_documents_query: query };
	},
	[types.VALIDATION_ERRORS]: (state, errs) => {
		state.props = { ...state.props, validation_errors: errs };
	}
};
