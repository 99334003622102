const types = {
	cashFromCashdesk: 'cash_from_cashdesk',
	cashFromBank: 'cash_from_bank',
	cashFromAnotherSalepoint: 'cash_from_another_salepoint',
	repaymentByGuiltyPerson: 'repayment_by_guilty_person',
	capitalizationOfExcess: 'capitalization_of_excess',
	compensation: 'compensation',
	excessFromCashdeskApp: 'excess',
	cashFromCentral: 'cash_from_central',
	cashFromTransitionalSalepoint: 'cash_from_transitional_salepoint',
	cashFromSelfServiceTerminal: 'cash_from_self_service_terminal',
	cashFromCourier: 'cash_from_courier',
	appealClaim: 'appeal_claim', //только outgoing
	cashToCashdesk: 'cash_to_cashdesk',
	cashToBank: 'cash_to_bank',
	cashToCourier: 'cash_to_courier',
	cashToAnotherSalepoint: 'cash_to_another_salepoint',
	cashLackToGuiltyPerson: 'cash_lack_to_guilty_person',
	cashLackWithoutGuiltyPerson: 'cash_lack_without_guilty_person',
	encashmentByCashier: 'encashment_by_cashier',
	cashLackFromCashdeskApp: 'cash_lack',
	cashToCentral: 'cash_to_central',
	cashToTransitionalSalepoint: 'cash_to_transitional_salepoint'
};

const locale = {
	[types.cashFromCashdesk]: 'оприбуткування грошових коштів з другорядної каси',
	[types.cashFromBank]: 'підкріплення банком ПНФП',
	[types.cashFromAnotherSalepoint]: 'підкріплення з іншого ПНФП',
	[types.repaymentByGuiltyPerson]: 'погашення нестачі винною особою',
	[types.capitalizationOfExcess]: 'оприбуткування надлишків',
	[types.compensation]: 'компенсація рах.3741',
	[types.excessFromCashdeskApp]: 'надлишок',
	[types.cashFromCentral]: 'Підкріплення з ЦК(00000)',
	[types.appealClaim]: 'звернення за претензією рах.3771',
	[types.cashToCashdesk]: 'видача грошових коштів на другорядну касу',
	[types.cashToCourier]: 'Переміщення розмін',
	[types.cashToBank]: 'здача готівки в банк (+СКО)',
	[types.cashToAnotherSalepoint]: 'підкріплення іншого ПНФП',
	[types.cashLackToGuiltyPerson]: 'віднесення нестачі на винну особу',
	[types.cashLackWithoutGuiltyPerson]: 'нестача без винної особи',
	[types.encashmentByCashier]: 'здача готівки на рахунок в банку',
	[types.cashLackFromCashdeskApp]: 'нестача',
	[types.cashToCentral]: 'Підкріплення ЦК(00000)',
	[types.cashToTransitionalSalepoint]: 'рух коштів (видача)',
	[types.cashFromTransitionalSalepoint]: 'рух коштів (оприбуткування)',
	[types.cashFromSelfServiceTerminal]: 'Грошові кошти з ПТКС',
	[types.cashFromCourier]: 'Переміщення розмін'
};

module.exports = {
	enum: types,
	locale: locale
};
