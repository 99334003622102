import { core, http, lookup } from 'novapay-ui';
import BigNumber from 'bignumber.js';

import handleError from '@services/handle-api-error';

const types = core.createTypes('control_center');

const generateCashMachineCode = (context, payload = {}) => {
	return http('/v3/otp/cash-machine', { method: 'POST', data: payload }).then((res) => {
		if (!handleError()(res, context, 200)) {
			return;
		}
		context.commit(types.SHOW_CASH_MACHINE_OTP);
	});
};

const openCashdesk = (context, payload = {}) => {
	return http('/v3/salepoint/cashdesk', { method: 'PUT', data: payload }).then((res) => {
		if (!handleError()(res, context, 200, types.HIDE_CASH_MACHINE_OTP, types.HIDE_CASH_MACHINE_OTP)) {
			return;
		}
		context.commit(types.CLOSE_SELECT_CASH_MACHINE_MODAL);
	});
};

const closeCashdesk = (context, payload) => {
	let { cashdesk } = context.rootState.root.props;
	let balance = new BigNumber(cashdesk.in_balance).minus(cashdesk.out_balance);

	if (!balance.isZero()) {
		return toggleGiveAcceptCashModal(context, { cashdesk_closal: true });
	}
	return http('/v3/salepoint/cashdesk', { method: 'DELETE' }).then((res) => {
		if (!handleError()(res, context, 200)) {
			return;
		}
	});
};

const startCashdesk = (context, payload) => {
	return http('/v3/salepoint/cashdesk/start', { method: 'POST' }).then((res) => {
		if (!handleError()(res, context, 200)) {
			return;
		}
	});
};

const createCashFromCashdesk = async (context, payload) => {
	let stopRes = await http('/v3/salepoint/cashdesk/stop', { method: 'POST' });
	if (!handleError()(stopRes, context, 200)) {
		return;
	}
	let res = await http('/v3/orders/cash-from-cashdesk', { method: 'PUT', data: payload });
	if (!handleError()(res, context, 200, types.VALIDATION_ERRORS)) {
		return;
	}
	return context.commit(types.CLOSE_GIVE_ACCEPT_CASH_MODAL);
};

const toggleSelectCashMachineModal = async (context) => {
	if (context.state.state_key.render === 'select_cash_machine_modal') {
		return context.commit(types.CLOSE_SELECT_CASH_MACHINE_MODAL);
	}
	return context.commit(types.OPEN_SELECT_CASH_MACHINE_MODAL);
};

const toggleGiveAcceptCashModal = (context, { tab, cashdesk_closal } = {}) => {
	if (context.state.state_key.render === 'give_accept_cash_modal') {
		return context.commit(types.CLOSE_GIVE_ACCEPT_CASH_MODAL);
	}
	return context.commit(types.OPEN_GIVE_ACCEPT_CASH_MODAL, { tab, cashdesk_closal });
};

const lookupCashMachines = (context, { query, cb }) => {
	return lookup(`/v3/lookups/cash-machines/code?query=${query || ''}&only_active=true`).then((res) => {
		handleError()(res, context) && cb(res);
	});
};

export default {
	generateCashMachineCode,
	openCashdesk,
	closeCashdesk,
	toggleSelectCashMachineModal,
	lookupCashMachines,
	toggleGiveAcceptCashModal,
	startCashdesk,
	createCashFromCashdesk
};
