import { core } from 'novapay-ui';

import commonStore from '@services/common-store';

const types = core.createTypes('salepoints_access');
const { mutations } = commonStore('salepoints_access');

export default {
	...mutations,
	[types.INIT_MODULE]: (state) => {
		state.props = { ...state.props };
	},
	[types.OPEN_DIIA_BARCODE]: (state, diiaPhone) => {
		state.props = { ...state.props, diiaPhone };
	},
	[types.DIGITAL_DOCUMENT_REQUEST_LOADING]: (state, { uuid, timeout } = {}) => {
		state.props = {
			...state.props,
			digitalDocumentRequestUuid: uuid,
			digitalDocTimeout: uuid && timeout ? timeout : null
		};
	},
	[types.SET_VERIFIED_EMPLOYEE]: (state, { employeeData }) => {
		state.props = {
			...state.props,
			employeeData
		};
	},
	[types.DOCUMENT_VALIDATION_ERRORS]: (state, errors) => {
		state.props = { ...state.props, errors };
	},
	[types.SET_IS_LOADING_DIIA_DATA](state, isLoading) {
		state.props = {
			...state.props,
			isLoadingDiiaData: isLoading
		};
	},
	[types.SET_IS_LOADING_CHECKING](state, isLoadingChecking) {
		state.props = {
			...state.props,
			isLoadingChecking
		};
	}
};
