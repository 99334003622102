<template>
	<NP-Modal :toggle="toggle">
		<img :src="$options.logo" alt="NovaPay logo violet" class="logo" />
		<form v-show="step === 'phone'" class="form" @submit.prevent="onSubmit">
			<h4 class="title">Запропонуй завантажити додаток NovaPay та отримувати до 20% вигоди</h4>
			<p class="subtitle">
				Відправ СМС на <strong>{{ phoneValue }}</strong
				><br />
				або запитай інший номер
			</p>
			<div class="buttons">
				<NP-Button
					:disabled="loading"
					@click.stop="
						() => {
							step = 'enter';
							localPhone = null;
						}
					"
				>
					Ввести інший номер
				</NP-Button>
				<NP-Button type="submit" priority="medium" :loading="loading">Відправити СМС</NP-Button>
			</div>
		</form>
		<form v-show="step === 'enter'" class="form" @submit.prevent="onSubmit">
			<h4 class="title">Введи номер телефону для відправки СМС</h4>
			<NP-Input ref="phoneInput" type="phone" v-model="localPhone" label="Номер телефону" class="phone-input" />
			<NP-Button
				type="submit"
				priority="medium"
				:disabled="sendSmsButtonDisabled"
				:loading="loading"
				class="submit-button"
			>
				Відправити СМС
			</NP-Button>
		</form>
	</NP-Modal>
</template>
<script>
import { validatePhone } from '@services/validation';
import logo from '@assets/images/novapay-violet.svg';
const PHONE_FILTER_REGEXP = /[^+\d]/g;
export default {
	name: 'AppInviteModal',
	props: {
		toggle: { type: Function, default: () => {} },
		params: { type: Object, default: null },
		loadingActions: { type: Array, default: () => [] }
	},
	data() {
		const { phone: paramsPhone } = this.params ?? {};
		const phoneValue = typeof paramsPhone === 'string' ? paramsPhone.replaceAll(PHONE_FILTER_REGEXP, '') : '';
		const initialStep = validatePhone(phoneValue) ? 'phone' : 'enter';
		return {
			localPhone: initialStep === 'phone' ? phoneValue : null,
			step: initialStep
		};
	},
	computed: {
		loading() {
			return this.loadingActions.includes('appInvite');
		},
		phoneValue() {
			return this.localPhone ? this.localPhone.replaceAll(PHONE_FILTER_REGEXP, '') : null;
		},
		sendSmsButtonDisabled() {
			return !validatePhone(this.phoneValue);
		}
	},
	methods: {
		async onSubmit() {
			this.$emit('send', {
				phone: this.phoneValue
			});
		}
	},
	watch: {
		step() {
			if (this.step === 'enter') {
				this.$nextTick(() => {
					this.$refs.phoneInput.$refs.input.focus();
				});
			}
		}
	},
	logo
};
</script>

<style scoped lang="scss">
@import '@assets/scss/variables.scss';

::v-deep .modal-body {
	padding-top: 0 !important;
	box-sizing: content-box !important;
	min-width: 375px;
	max-width: 375px;
}
.logo {
	margin: -8px auto 14px;
	display: block;
	width: 44px;
	height: 44px;
}
.form {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 0;
}
.buttons {
	display: flex;
	gap: 8px;
}
.logo-wrapper {
	display: block;
	text-align: center;
}
.title {
	font-size: 18px;
	font-weight: 600;
	color: $gray-50;
	text-align: center;
}
.subtitle {
	margin-top: 12px;
	margin-bottom: 16px;
	text-align: center;
}
.phone-input {
	margin: 20px 0;
	min-width: 210px;
}
.submit-button {
	min-width: 210px;
}
</style>
