<script>
import { mapActions } from 'vuex';

export default {
	name: 'AppInviteButton',
	props: {
		phone: {
			type: String,
			default: null
		}
	},
	methods: {
		...mapActions({
			toggleAppInviteModal: 'root/toggleAppInviteModal'
		}),
		toggle() {
			this.toggleAppInviteModal({ phone: this.phone });
		}
	}
};
</script>

<template>
	<NP-Button type="button" priority="medium" class="app-invite-button" @click.stop="toggle">
		<div class="pop-up">
			<div class="pop-up__title">Запропонуй завантажити<br />додаток NovaPay<br />та отримувати до 20% вигоди</div>
			<div class="pop-up__body-text">Відправ СМС із посиланням<br />для завантаження додатку</div>
		</div>
		Додаток NovaPay
	</NP-Button>
</template>

<style scoped lang="scss">
@import '@assets/scss/variables';

$icon-loudspeaker: '~@assets/images/icon-loudspeaker.svg';

.button.app-invite-button {
	position: relative;
	background-color: $violet !important;
	border: none !important;
	&:focus,
	&:hover {
		background-color: $violet !important;
		.pop-up {
			visibility: visible;
		}
	}
}
.pop-up {
	position: absolute;
	top: 48px;
	right: 0;
	z-index: 100;
	visibility: hidden;
	padding: 12px 12px 12px 44px;
	background: $gray-50;
	border-radius: 6px;
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
	color: $white;
	font-size: 12px;
	text-align: start;
	&::before {
		width: 20px;
		height: 20px;
		background: url($icon-loudspeaker) no-repeat center center;
		content: '';
		position: absolute;
		left: 15px;
		top: 15px;
	}
	&::after {
		width: 0;
		height: 0;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-top: 8px solid transparent;
		border-bottom: 8px solid $gray-50;
		content: '';
		position: absolute;
		top: -16px;
		right: calc(25% - 9px);
	}
	.pop-up__title {
		min-width: 200px;
		margin-bottom: 4px;
		font-size: 16px;
		font-weight: 600;
	}
	.pop-up__body-text {
		font-size: 12px;
		font-weight: 300;
		line-height: 16px;
	}
}
</style>
