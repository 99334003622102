export function setCookie(name, value, expires) {
	let cookieString = `${name}=${value}; path=/`;
	if (expires) {
		cookieString += `; expires=${expires.toUTCString()}`;
	}
	document.cookie = cookieString;
}

export function getCookie(name) {
	const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
	return match ? match[2] : null;
}

export function deleteCookie(name) {
	document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}
