const types = {
	online: 'online',
	offline: 'offline',
	onlineActualization: 'online_actualization',
	privateEntrepreneur: 'private_entrepreneur',
	crsOnboarding: 'crs_onboarding',
	offlineActualization: 'offline_actualization'
};

const locale = {
	[types.online]: 'онлайн',
	[types.offline]: 'оффлайн',
	[types.onlineActualization]: 'онлайн актуалізація',
	[types.privateEntrepreneur]: 'фоп',
	[types.crsOnboarding]: 'CRS',
	[types.offlineActualization]: 'оффлайн актуалізація'
};

module.exports = {
	enum: types,
	locale: locale
};
