<template>
	<NP-Modal
		class="alert-modal"
		priority="error"
		:toggle="close"
		:disable-close="disableClose"
		:disable-click-outside="disableClickOutside"
	>
		<div :class="{ 'error-alert-body': true, 'no-close': !close }" :style="{ paddingTop: disableClose ? '20px' : '0' }">
			<img :src="$options.modalError" />
			<h3 class="title">
				{{ title | capitalize }}
			</h3>
			<p class="text">
				{{ text | capitalize }}
			</p>
			<div class="close-button">
				<NP-Button v-if="closeButton && close" priority="high" @click.stop="() => close(true)">
					{{ closeButton | capitalize }}
				</NP-Button>
			</div>
		</div>
	</NP-Modal>
</template>

<script>
import modalError from '@assets/images/modal-error.svg';

export default {
	name: 'ErrorAlert',
	props: {
		title: { type: String, default: null },
		text: { type: String, default: null },
		closeButton: { type: String, default: null },
		code: { type: String, default: null },
		metadata: { type: Object, default: null },
		close: { type: Function, default: null },
		disableClose: { type: Boolean, default: false },
		disableClickOutside: { type: Boolean, default: false }
	},
	modalError,
	filters: {
		capitalize: function (value) {
			if (!value) {
				return '';
			}
			value = value.toString();
			return value.charAt(0).toUpperCase() + value.slice(1);
		}
	}
};
</script>
<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

::v-deep .modal-container {
	.modal-body {
		padding-top: 0 !important;
		.error-alert-body {
			width: 400px;
			img {
				display: block;
				margin: -16px auto 16px auto;
			}
			&.no-close {
				img {
					display: block;
					margin: 16px auto 16px auto;
				}
			}
			.title {
				text-align: center;
				margin-bottom: 12px;
			}
			.text {
				text-align: center;
				color: $gray-50;
				word-break: break-word;
				white-space: pre-line;
			}
			.close-button {
				.text {
					color: $white;
				}
				display: flex;
				justify-content: center;
				margin-top: 12px;
			}
		}
	}
	@include mobile {
		width: 100%;
		.modal-body .error-alert-body {
			width: 100%;
		}
	}
}
</style>
