<template>
	<div class="actions-wrap" @mouseleave="() => toggleActions(false)">
		<div v-if="!showActions" @mouseenter="() => toggleActions(true)" class="dots-btn">
			<div class="icon-vdots" slot="icon" />
		</div>
		<div v-else class="actions">
			<NP-Button
				v-if="options.confirm"
				variant="square"
				priority="high"
				@click.stop="options.confirm.click"
				:loading="options.confirm.isLoading"
				:disabled="oneIsLoading"
			>
				<div slot="icon" class="icon-confirm" />
			</NP-Button>
			<NP-Button
				v-if="options.close"
				variant="square"
				:priority="options.close.priority || 'medium'"
				@click.stop="options.close.click"
				:title="options.close.title ? `${options.close.title}` : undefined"
				:loading="options.close.isLoading"
				:disabled="oneIsLoading"
			>
				<div slot="icon" class="icon-close" />
			</NP-Button>
			<NP-Button
				v-if="options.remove"
				variant="square"
				priority="medium"
				@click.stop="options.remove.click"
				:loading="options.remove.isLoading"
				:disabled="oneIsLoading"
			>
				<div slot="icon" class="icon-remove" />
			</NP-Button>
			<NP-Button
				v-if="options.edit"
				variant="square"
				priority="medium"
				@click.stop="options.edit.click"
				:loading="options.edit.isLoading"
				:disabled="oneIsLoading"
			>
				<div slot="icon" class="icon-edit" />
			</NP-Button>
			<NP-Button
				v-if="options.print"
				variant="square"
				priority="medium"
				@click.stop="options.print.click"
				:loading="options.print.isLoading"
				:disabled="oneIsLoading"
			>
				<div slot="icon" class="icon-print" />
			</NP-Button>
			<NP-Button
				v-if="options.add"
				variant="square"
				priority="medium"
				@click.stop="options.add.click"
				:loading="options.add.isLoading"
				:disabled="oneIsLoading"
			>
				<div slot="icon" class="icon-plus" />
			</NP-Button>
		</div>
	</div>
</template>

<script>
import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';
import forOwn from 'lodash/forOwn';

export default {
	name: 'RowActions',
	props: {
		options: { type: Object, default: () => ({}) }
	},
	data: () => ({
		actionsShown: false
	}),
	methods: {
		toggleActions(shouldOpen) {
			this.actionsShown = shouldOpen;
		}
	},
	computed: {
		showActions() {
			return this.actionsShown || this.hasOnlyOneAction || this.oneIsLoading;
		},
		hasOnlyOneAction() {
			return Object.keys(omitBy(this.options, isUndefined)).length === 1;
		},
		oneIsLoading() {
			let loading = false;
			forOwn(this.options, (opt, key) => {
				if (opt?.isLoading) {
					loading = opt.isLoading;
				}
			});
			return loading;
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/mixins';
@import '@assets/scss/variables';

$icon-print: '~@assets/images/icon-print.svg';
$icon-confirm: '~@assets/images/icon-confirm.svg';
$icon-edit: '~@assets/images/icon-edit.svg';
$icon-remove: '~@assets/images/icon-remove.svg';
$icon-close: '~@assets/images/icon-close.svg';
$icon-vdots: '~@assets/images/icon-vdots.svg';
$icon-plus: '~@assets/images/icon-plus.svg';

.actions-wrap {
	position: relative;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	height: 100%;
	.dots-btn {
		height: 32px;
		width: 32px;
		margin-right: 12px;
		background: $gray-50;
		box-shadow: 0 2px 6px rgba(96, 108, 156, 0.35);
		border: 1px solid $gray-50;
		border-radius: 4px;
		text-align: center;
		position: relative;
		&:hover {
			cursor: pointer;
			background: #373a3e;
		}
		.icon-vdots {
			position: absolute;
			@include make-icon($icon-vdots, $white, 22px, 22px);
			left: calc(50% - 11px);
			top: calc(50% - 11px);
		}
	}
	.actions {
		right: 12px;
		height: 30px;
		margin: auto;
		position: absolute;
		display: flex;
		.button {
			width: 32px;
			height: 32px;
			margin-right: 6px;
			&:last-child {
				margin-right: 0;
			}
			.icon-print {
				@include make-icon($icon-print, $white, 20px, 20px);
			}
			.icon-edit {
				@include make-icon($icon-edit, $white, 20px, 20px);
			}
			.icon-remove {
				@include make-icon($icon-remove, $white, 20px, 20px);
			}
			.icon-close {
				@include make-icon($icon-close, $white, 20px, 20px);
			}
			.icon-confirm {
				@include make-icon($icon-confirm, $white, 20px, 20px);
			}
			.icon-plus {
				@include make-icon($icon-plus, $white, 20px, 20px);
			}
		}
	}
}
</style>
