import { core, http } from 'novapay-ui';
import { v4 as uuid } from 'uuid';

import commonStore from '@services/common-store';
import handleError from '@services/handle-api-error';

const { actions: commonStoreActions } = commonStore('salepoints_access');
const types = core.getTypes('salepoints_access');
const rootTypes = core.getTypes('root');

const initModule = async (context) => {
	context.commit(types.INIT_MODULE, null);
	return commonStoreActions.hideRootSplashScreen(context);
};

const requestDigitalDocument = async (context, { barcode }) => {
	context.commit(types.SET_IS_LOADING_DIIA_DATA, true);
	let requestUuid = uuid();
	let digitalDocTimeout = setTimeout(() => {
		context.commit(types.SET_IS_LOADING_DIIA_DATA, false);
		context.commit(
			rootTypes.namespaced.ERROR_ALERT,
			{ text: `Перевищено час очікування відповіді. Перевірте документ та спробуйте знову.` },
			{ root: true }
		);
	}, 60 * 1000 * 5);

	context.commit(types.DIGITAL_DOCUMENT_REQUEST_LOADING, {
		uuid: requestUuid,
		timeout: digitalDocTimeout
	});

	const res = await http({
		url: '/v3/novaclients/digital-document/diia',
		method: 'post',
		data: {
			barcode,
			requestUuid,
			phone: '',
			taken_reason: 'salepoint-access-check',
			is_for_nc3: false,
			novaclients_id: null,
			isUserDocCheck: true
		}
	});
	if (!handleError({ processing: processingHandler })(res, context)) {
		clearTimeout(digitalDocTimeout);
		context.commit(types.SET_IS_LOADING_DIIA_DATA, false);
		return context.commit(types.DIGITAL_DOCUMENT_REQUEST_LOADING);
	}
};

export const checkDocumentDetails = async (context, payload = {}) => {
	context.commit(types.SET_IS_LOADING_CHECKING, true);
	if (context?.state?.props?.employeeData) {
		context.commit(types.SET_VERIFIED_EMPLOYEE, {
			employeeData: {}
		});
	}

	const { type, series, number } = payload;
	let data = {
		document: {
			type,
			series,
			number
		}
	};
	let res = await http(`/v3/salepoint/get-employee-details`, { method: 'post', data });
	handleError({ processing: processingHandler })(res, context);
	if (res.status === 200) {
		context.commit(types.SET_VERIFIED_EMPLOYEE, {
			employeeData: {
				...res.data
			}
		});
	}
	context.commit(types.SET_IS_LOADING_CHECKING, false);
	return context.state.props.employeeData;
};

const receiveDigitalDocumentEvent = async (context, data) => {
	context.commit(types.SET_IS_LOADING_DIIA_DATA, false);
	let { message, res, is_successful } = data.payload;
	let { digitalDocTimeout, digitalDocumentRequestUuid } = context.state.props;
	if (digitalDocTimeout) {
		clearTimeout(digitalDocTimeout);
	}
	if (digitalDocumentRequestUuid) {
		context.commit(types.DIGITAL_DOCUMENT_REQUEST_LOADING);
		if (!is_successful) {
			return context.commit(rootTypes.namespaced.ERROR_ALERT, { text: message }, { root: true });
		}
		if (res) {
			const document = { type: res.type, series: res.series, number: res.number };
			await checkDocumentDetails(context, document);
		}
	}
};

export const processingHandler = (context, res) => {
	let text = '';
	if (['EmployeeDocumentNotValidError', 'EmployeeNotActiveError'].includes(res.data.code)) {
		text = 'Відмов співробітнику в доступі до ПНФП';
	} else if (['SalepointAccessDeniedError'].includes(res.data.code)) {
		text = 'Звернись до територіального менеджера або головного касира NovaPay для уточнення';
	} else if (['EmployeeDocumentNotFoundError'].includes(res.data.code)) {
		text = 'В доступі відмовлено. Звернись до територіального менеджера/головного касира NovaPay для уточнень';
	}
	let errorAlert = { title: res.data.error, code: res.data.code, text };
	context.commit(types.SET_IS_LOADING_DIIA_DATA, false);
	context.commit(rootTypes.namespaced.ERROR_ALERT, errorAlert, { root: true });
	return false;
};

const createToggle =
	(stateKey, handleClose, handleOpen, getForceClose = () => false) =>
	async (context, { ...payload } = {}) => {
		let openType = types['OPEN_' + stateKey.toUpperCase()];
		try {
			var closeType = types['CLOSE_' + stateKey.toUpperCase()];
		} catch (e) {
			null;
		}
		let forceClose = getForceClose(context);
		if (context.state?.state_key?.render === stateKey || forceClose) {
			if (handleClose) {
				return await handleClose(context, payload);
			} else if (closeType) {
				return context.commit(closeType, payload);
			}
		}
		if (handleOpen) {
			await handleOpen(context, payload);
		} else {
			context.commit(openType, payload);
		}
	};

export const toggles = {
	toggleDiiaBarcode: createToggle(
		'diia_barcode',
		(context, { barcode } = {}) => {
			if (barcode) {
				requestDigitalDocument(context, { barcode });
			}
			return context.commit(types.CLOSE_DIIA_BARCODE);
		},
		(context, { phone } = {}) => context.commit(types.OPEN_DIIA_BARCODE, phone),
		(context) => context.state.state_key?.render?.includes?.('diia_barcode')
	)
};

const resetEmployeeData = (context) => {
	context.commit(types.SET_VERIFIED_EMPLOYEE, {
		employeeData: null
	});
};

export default {
	...commonStoreActions,
	...toggles,
	requestDigitalDocument,
	receiveDigitalDocumentEvent,
	checkDocumentDetails,
	initModule,
	resetEmployeeData
};
