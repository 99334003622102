<template>
	<div :class="{ mobile: isMobileDevice }">
		<ErrorAlert
			v-for="(alert, index) in errorAlerts"
			:key="index"
			:title="alert.title"
			:code="alert.code"
			:text="alert.text"
			:close-button="alert.closeButton"
			:metadata="alert.metadata"
			:disable-close="alert.disableClose"
			:disable-click-outside="alert.disableClickOutside"
			:close="
				() => {
					removeAlert(alert.id);
					if (alert.close) {
						alert.close();
					}
				}
			"
		/>
		<NP-Snackbars :data="infoAlerts" :close="removeAlert" />
	</div>
</template>

<script>
import upperFirst from 'lodash/upperFirst';
import isMobileDevice from '@services/is-mobile-device';
export default {
	name: 'Alerts',
	props: {
		alertsData: { type: Array, default: null },
		removeAlert: { type: Function, default: null }
	},
	computed: {
		isMobileDevice() {
			return isMobileDevice;
		},
		alerts() {
			return this.alertsData ? [...this.alertsData] : [];
		},
		errorAlerts() {
			return this.alerts.filter((a) => a.severity === 'error').sort((a, b) => a.id.localeCompare(b.id));
		},
		infoAlerts() {
			return this.alerts
				.filter((a) => a.severity !== 'error')
				.map((a) => ({ ...a, title: upperFirst(a.title) }))
				.reverse();
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.snackbars {
	bottom: 16px;
}
.mobile {
	::v-deep .snackbars {
		width: 100vw;
		left: 0;
		bottom: 0;
	}
}
</style>
