import { core } from 'novapay-ui';

const types = core.createTypes('control_center');

export default {
	[types.OPEN_CASHDESK_ACTION_MODAL]: (state, cashdeskActionModalData) => {
		state.props = { ...state.props, cashdeskActionModalData };
	},
	[types.CLOSE_CASHDESK_ACTION_MODAL]: (state) => {
		state.props = {
			...state.props,
			cashdeskActionModalData: null,
			validation_errors: null
		};
	},
	[types.OPEN_CASHDESK_COURIER_MODAL]: (state, cashdeskData) => {
		state.props = { ...state.props, cashdeskData };
	},
	[types.CLOSE_CASHDESK_COURIER_MODAL]: (state) => {
		state.props = {
			...state.props,
			cashdeskData: null
		};
	},
	[types.OPEN_PENDING_ORDERS_MODAL]: (state, cashdeskData) => {
		state.props = { ...state.props, cashdeskData };
	},
	[types.CLOSE_PENDING_ORDERS_MODAL]: (state) => {
		state.props = { ...state.props, cashdeskData: null };
	},
	[types.OPEN_CLOSAL_SHIFT_MODAL]: (state, cashdeskData) => {
		state.props = { ...state.props, cashdeskData };
	},
	[types.CLOSE_CLOSAL_SHIFT_MODAL]: (state) => {
		state.props = { ...state.props, cashdeskData: null };
	}
};
