const types = {
	cashier: 'cashier',
	cashierRP: 'cashier_rp',
	seniorCashier: 'senior_cashier',
	seniorCashierL: 'senior_cashier_l',
	cashierMobile: 'cashier_mobile',
	mobileCashierSpecialistPNFP: 'mobile_cashier_specialist_pnfp',
	specialistPNFP: 'specialist_pnfp',
	seniorCashierPNFP: 'senior_cashier_pnfp',
	chiefPNFP: 'chief_pnfp',
	cashierPNFP: 'cashier_pnfp',
	seniorCashierMobileGroupNP: 'senior_cashier_mobile_group_np',
};

const locale = {
	[types.cashier]: 'касир',
	[types.cashierRP]: 'касир рп',
	[types.seniorCashier]: 'старший касир',
	[types.cashierMobile]: 'касир мобільний',
	[types.mobileCashierSpecialistPNFP]: 'мобільний фахівець пнфп',
	[types.specialistPNFP]: 'фахівець пнфп',
	[types.seniorCashierPNFP]: 'старший касир пнфп',
	[types.chiefPNFP]: 'начальник пнфп',
	[types.cashierPNFP]: 'касир пнфп',
	[types.seniorCashierMobileGroupNP]: 'старший касир мобільна група (нп)',
	[types.seniorCashierL]: 'старший касир (л)',
};

module.exports = {
	enum: types,
	locale: locale
};
