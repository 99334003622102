import Promise from 'bluebird';
import { DateTime } from 'luxon';
import isEqual from 'lodash/isEqual';
import { core, http, centrifuge, lookup, validation } from 'novapay-ui';

import router from '@/router';
import handleError from '@services/handle-api-error';
import thermalPrinter from '@services/thermal-printer';
import rroPrinter from '@services/rro-printer';
import isMobileDevice from '@services/is-mobile-device';
import { deleteCookie } from '@services/cookies';

import supportStoreExtension from './extensions/support';
import posStoreExtension from './extensions/pos';
import awisBarcodeStoreExtension from './extensions/awis-barcode';

import { enum as centrifugoEventTypes } from '@repo/enums/centrifugo-event-types';
import { enum as edsStatuses } from '@repo/enums/electronic-digital-signature-requests-statuses';

const { actions: supportActions } = supportStoreExtension();
const { actions: posActions } = posStoreExtension();
const { actions: awisBarcodeActions } = awisBarcodeStoreExtension();

const types = core.getTypes('root');

const config = {
	centrifuge: {
		connection_timeout: 10000,
		refresh_endpoint: '/v3/centrifugo/token'
	}
};

const getAppData = async (context) => {
	let existingToken = context.state.props.token;
	let promises = [
		http('/v3/session'),
		!existingToken ? http('/v3/centrifugo/token', { method: 'post' }) : { status: 200, data: existingToken },
		http('/v3/salepoint', { headers: { 'Cache-Control': 'no-store, no-cache' } }),
		http('/v3/lists'),
		!thermalPrinter.connected && thermalPrinter.checkConnection(),
		!rroPrinter.connected && rroPrinter.checkConnection()
	];
	let [user, token, salepoint, lists] = await Promise.all(promises);

	if (user.status !== 200 || token.status !== 200 || salepoint.status !== 200 || lists.status !== 200) {
		return context.commit(types.APP_DATA_ERROR);
	}
	try {
		await centrifuge.init(config.centrifuge, token.data, { debug: true }, (res) => {
			if (res.status !== 200) {
				centrifuge.destroy();
				return context.commit(res.status === 403 ? types.APP_DATA_ERROR : types.ERROR);
			}
		});
	} catch (e) {
		context.commit(types.ERROR);
	}

	if (
		!user.data.has_gryada_key ||
		(user.data.eds_request &&
			(user.data.eds_request.status === edsStatuses.expiring ||
				(user.data.eds_request.status === edsStatuses.rejected && user.data.eds_request.has_another_eds)))
	) {
		context.commit(types.HIDE_ROOT_SPLASH_SCREEN);
		return context.commit(types.OPEN_GRYADA_KEY_FORM, user.data);
	}

	let timeFrameForPromoModal =
		DateTime.fromISO('2022-08-18T00:00').diffNow('hours').hours <= 0 &&
		DateTime.fromISO('2022-08-21T15:00').diffNow('hours').hours >= 0;

	if (!isMobileDevice && !localStorage.getItem('promoModalShown') && timeFrameForPromoModal) {
		togglePromoModal(context);
	}

	context.commit(types.APP_DATA_SUCCESS, {
		user: user.data,
		token: token.data,
		listsData: lists.data,
		salepointData: salepoint.data
	});
};

const validateLogin = validation.compile({
	properties: {
		login: { type: 'string', minLength: 1, maxLength: 255 },
		password: { type: 'string', minLength: 1, maxLength: 255 }
	}
});

const login = async (context, payload) => {
	let { data, errors } = validateLogin(payload);
	if (errors) {
		return context.commit(types.VALIDATION_ERRORS, errors);
	}
	let options = { method: 'POST', data };

	let res = await http('/v3/session/login', options);
	let handler = (context) => {
		context.commit(types.VALIDATION_ERRORS, [{ dataPath: '.login', message: 'Невірний логін або пароль' }]);
	};
	if (!handleError(handler)(res, context)) {
		return;
	}
	context.commit(types.LOGIN_SUCCESS, res.data);
	deleteCookie('isSelfTransferAlertShown');
};

const logout = async (context) => {
	let res = await http('/v3/session/logout', { method: 'POST' });
	if (res.status !== 200) {
		return context.commit(types.ERROR);
	}
	localStorage.removeItem('promoModalShown');
	router.push('/').catch((e) => {
		if (e.name === 'NavigationDuplicated') {
			return router.go();
		}
	});
	return context.commit(types.LOGOUT);
};

const transferUserToAnotherSalepoint = async (context, { id }) => {
	let res = await http(`v3/session/transfer-to-salepoint`, {
		method: 'POST',
		data: { salepoint_id: id }
	});
	const processingHandler = (context, res) => {
		let errorAlert =
			res.data.code === 'TransferToSalepointError'
				? {
						title: res.data.error,
						text:
							'Створи заявку на: СервісДеск → Технічна та операційна підтримка NovaPay -> ' +
							'7. Облікові записи працівників ПНФП -> 2. Редагування облікового запису касира',
						code: res.data.code
				  }
				: { text: res.data.error, code: res.data.code };
		context.commit(types.namespaced.ERROR_ALERT, errorAlert, { root: true });
		return false;
	};
	if (!handleError({ processing: processingHandler })(res, context)) {
		return context.commit(types.TOGGLE_MODAL, { modalName: 'transferUserModal', data: null });
	}
	// reinitialize centrifuge with new salepoint_id
	centrifuge.destroy();
	context.commit(types.DELETE_CENTRIFUGE_TOKEN);
	context.commit(types.TOGGLE_MODAL, { modalName: 'transferUserModal', data: null });
	return context.commit(types.TRANSFER_USER_TO_ANOTHER_SALEPOINT);
};

const lookupUsers = async (context, { query, setUserOptions }) => {
	let res = await lookup(`/v3/lookups/users/name?query=${query || ''}`);
	if (!handleError()(res, context)) {
		return;
	}
	setUserOptions(res.data);
};

const eventListener = (context, e) => {
	switch (e.type) {
		case centrifugoEventTypes.cashbookShift:
			return context.commit(types.CASHBOOK_SHIFT_EVENT, e.payload);
		case centrifugoEventTypes.cashbook:
			supportActions.getSignatureRequests(context);
			return context.commit(types.CASHBOOK_EVENT, e.payload);
		case centrifugoEventTypes.cashbookOrder:
			return context.commit(types.CASHBOOK_ORDER_EVENT, e.payload);
		case centrifugoEventTypes.cashdesk: {
			context.commit(types.UPDATE_OPENED_CASHDESK_SHIFTS, e.payload);
			const { user } = context.state.props;
			const canReceiveEvent = user?.id === e.payload.user_id;
			canReceiveEvent && context.commit(types.CASHDESK_EVENT, e.payload);
			break;
		}
		case centrifugoEventTypes.signatureRequest: {
			const { user } = context.state.props;
			const canReceiveEvent = e.payload?.signerUserIds?.includes(user?.id);
			context.commit(types.ADD_SIGNATURE_REQUEST, e.payload);
			if (canReceiveEvent) {
				supportActions.toggleNotificationMenu(context, { eventRecieve: true });
			}
			break;
		}
		case centrifugoEventTypes.successfulSign: {
			context.commit(types.REMOVE_SIGNATURE_REQUEST, e.payload);
			break;
		}
		case centrifugoEventTypes.posInfo: {
			const { posTerminals } = context.state.props;
			const canReceiveEvent = (posTerminals || []).map((p) => p.id).includes(e.payload?.id);
			if (canReceiveEvent) {
				let updatedTerminals = (posTerminals || []).map((p) => {
					return p.id === e.payload.id && !isEqual(p, e.payload) ? { ...p, ...e.payload } : p;
				});
				context.commit(types.UPDATE_POS_TERMINALS, updatedTerminals);
			}
			break;
		}
		case centrifugoEventTypes.posAlert: {
			const { posTerminals } = context.state.props;
			const canReceiveEvent = (posTerminals || []).map((p) => p.code).includes(e.payload?.pos_code);
			if (canReceiveEvent) {
				context.commit(types.ADD_ALERT, { ...e.payload, timeout: 2000, title: e.payload.message });
			}
			break;
		}
		case centrifugoEventTypes.posMessage: {
			const { posTerminals } = context.state.props;
			const canReceiveEvent = (posTerminals || []).map((p) => p.code).includes(e.payload?.pos_code);
			if (canReceiveEvent) {
				if (e.payload.type === 'transactions') {
					let updatedTerminals = (posTerminals || []).map((p) => {
						let transactionsData =
							e.payload.reason === 'no data'
								? { transactions: [], transactions_total: 0 }
								: {
										transactions: e.payload.transactions || [],
										transactions_total: e.payload.total
								  };
						return p.code === e.payload.pos_code ? { ...p, ...transactionsData } : p;
					});
					context.commit(types.UPDATE_POS_TERMINALS, updatedTerminals);
				}
			}
			break;
		}
		case centrifugoEventTypes.thermalPrinter: {
			const { user } = context.state.props;
			const canReceiveEvent = user?.id === parseInt(e.payload.user_id);
			return canReceiveEvent && thermalPrinter.print(types, context, e.payload.pdf);
		}
		case centrifugoEventTypes.rroPrinter: {
			const { cashier_login } = router.currentRoute.query;
			const canReceiveEvent = cashier_login === e.payload.user_login;
			return canReceiveEvent && rroPrinter.print(types, context, e.payload.print_lines);
		}
		default:
			return;
	}
};

const removeAlert = (context, { id }) => context.commit(types.REMOVE_ALERT, id);

const searchOperations = async (context, { search, searchType }) => {
	let res = await http('/v3/operations/search', { query: { search, search_type: searchType } });
	if (!handleError()(res, context)) {
		return;
	}
	context.commit(types.SEARCH_OPERATIONS_RESULTS, res.data);
};

const clearSearchResults = (context) => context.commit(types.SEARCH_OPERATIONS_RESULTS, null);

const closeSnackbar = (context, id) => {
	return context.commit(types.REMOVE_SNACKBAR, id);
};

const togglePromoModal = (context) => {
	if (!context.state.props?.toggles?.promoModal) {
		context.commit(types.TOGGLE_MODAL, { modalName: 'promoModal', data: 1 });
	} else {
		context.commit(types.TOGGLE_MODAL, { modalName: 'promoModal', data: null });
		localStorage.setItem('promoModalShown', true);
	}
};

const toggleAppInviteModal = (context, payload = {}) => {
	if (!context.state.props?.toggles?.appInviteModal) {
		context.commit(types.TOGGLE_MODAL, { modalName: 'appInviteModal', data: payload });
	} else {
		context.commit(types.TOGGLE_MODAL, { modalName: 'appInviteModal', data: null });
	}
};

const sendAppInvite = async (context, payload) => {
	context.commit('SET_LOADING_ACTION', { action: 'appInvite' });
	const res = await http('/v3/sms/app-invite', { method: 'POST', data: payload });
	context.commit('RESET_LOADING_ACTION', { action: 'appInvite' });
	toggleAppInviteModal(context);
	if (res.data.code === 'TooManyRetriesSMSError') {
		context.commit(types.ADD_SNACKBAR, {
			title: res.data.error,
			variant: 'error'
		});
		return;
	}
	if (res.data.code === 'IncorrectClientStatusError') {
		context.commit(types.ERROR_ALERT, {
			title: 'СМС повідомлення не надіслано',
			text: res.data.error,
			variant: 'error'
		});
		return;
	}
	if (!handleError({ validation: () => {} })(res, context)) {
		context.commit(types.ADD_SNACKBAR, {
			title: 'Запит на відправку СМС не виконано',
			variant: 'error'
		});
		return;
	}
	context.commit(types.ADD_SNACKBAR, {
		title: 'Запит на відправку СМС прийнято',
		variant: 'success'
	});
};

export default {
	login,
	logout,
	getAppData,
	eventListener,
	removeAlert,
	searchOperations,
	clearSearchResults,
	transferUserToAnotherSalepoint,
	lookupUsers,
	closeSnackbar,
	togglePromoModal,
	toggleAppInviteModal,
	sendAppInvite,
	...supportActions,
	...posActions,
	...awisBarcodeActions
};
