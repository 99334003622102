import find from 'lodash/find';
import semver from 'semver';
import { enum as cashbookShiftStatuses } from '../../../../../../enums/cashbook-shift-statuses';
import { enum as cashbookStatuses } from '../../../../../../enums/cashbook-statuses';
import { enum as cashdeskStatuses } from '../../../../../../enums/cashdesk-statuses';
import { enum as posMerchantBanks } from '../../../../../../enums/pos-merchant-banks';
import { locale as userPositionsLocale, enum as userPositions } from '../../../../../../enums/user-positions';

const MIN_POS_DAEMON_VERSION = '1.7.12';

export default {
	isChiefCashier: (state) => {
		let { cashbook_shift, user } = state.props;
		return cashbook_shift?.user_id === user?.id && cashbook_shift?.status === cashbookShiftStatuses.opened;
	},
	isCashbookShiftClosed: (state) =>
		!state.props.cashbook_shift || state.props.cashbook_shift.status === cashbookShiftStatuses.closed,
	isCashbookClosed: (state) => !state.props.cashbook || state.props.cashbook.status === cashbookStatuses.closed,
	isCashbookClosing: (state) => state.props.cashbook?.status === cashbookStatuses.closing,
	isCashbookOpened: (state) => state.props.cashbook?.status === cashbookStatuses.opened,
	isCashdeskOpened: (state) => state.props.cashdesk?.status === cashdeskStatuses.opened,
	isCashdeskStopped: (state) => state.props.cashdesk?.status === cashdeskStatuses.stopped,
	isCashdeskClosed: (state) => !state.props.cashdesk || state.props.cashdesk.status === cashdeskStatuses.closed,
	isChiefOfSalepoint: (state) =>
		state.props?.user?.position?.toLowerCase() === userPositionsLocale[userPositions.chiefPNFP] || false,
	isTerritorialManager: (state) =>
		state.props.salepoint && state.props.salepoint?.territorial_manager_id === state.props.user?.id,
	isBranchManager: (state) =>
		state.props.salepoint && state.props.salepoint?.branch_manager_id === state.props.user?.id,
	posForDocument: (state) => {
		return state.props.posTerminals?.length
			? find(
					[...state.props.posTerminals].sort((a, b) => a.merchant_bank.localeCompare(b.merchant_bank)),
					// use aval pos by default if both are connected
					(d) =>
						d.merchant_bank === posMerchantBanks.tas ||
						d.merchant_bank === posMerchantBanks.aval ||
						(d.merchant_bank === posMerchantBanks.oschadbank && d.code.length === 24)
			  )
			: null;
	},
	posDaemonVersion: (state) => state.props.posDaemonVersion,
	isPosDaemonOutdated: (state) =>
		state.props?.posDaemonVersion ? semver.lt(state.props.posDaemonVersion, MIN_POS_DAEMON_VERSION) : false,
	activePosTerminals: ({ props }) => props.posTerminals?.filter(({ status }) => status === 'active') || [],
	activePosTerminal: (state, { activePosTerminals }) => activePosTerminals?.[0] || null,
	activePosTerminalMerchantBank: (state, { activePosTerminal }, { root }) => {
		return find(root.props.lists['pos-merchant-banks'], { id: activePosTerminal.merchant_bank }) || {};
	}
};
