import Vue from 'vue';
import Vuex from 'vuex';
import { core } from 'novapay-ui';

import root from '@/modules/root/store';
import control_center from '@/modules/control-center/store';
import cashbooks from '@/modules/cashbooks/store';
import acts from '@/modules/acts/store';
import orders from '@/modules/orders/store';
import printed_documents from '@/modules/printed-documents/store';
import payment from '@/modules/payment/store';
import operations from '@/modules/operations/store';
import refreshments from '@/modules/refreshments/store';
import archive_documents from '@/modules/archive-documents/store';
import archive_packages from '@/modules/archive-packages/store';
import cash_management from '@/modules/cash-management/store';
import naftogaz_applications from '@/modules/naftogaz-applications/store';
import inventory_record from '@/modules/inventory-record/store';
import operation_recipient_claims from '@/modules/operation-recipient-claims/store';
import workplaces from '@/modules/workplaces/store';
import profile from '@/modules/profile/store';
import salepoints_access from '@/modules/salepoints-access/store';

Vue.use(Vuex);

export default new Vuex.Store({
	strict: process.env.NODE_ENV !== 'production',
	modules: {
		root,
		control_center,
		cashbooks,
		acts,
		orders,
		printed_documents,
		payment,
		operations,
		refreshments,
		archive_documents,
		archive_packages,
		cash_management,
		naftogaz_applications,
		inventory_record,
		operation_recipient_claims,
		workplaces,
		profile,
		salepoints_access
	},
	plugins: [core.statePlugin]
});
