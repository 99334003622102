import { core } from 'novapay-ui';

import actions from './actions';
import mutations from './mutations';

const types = core.createTypes('orders');

export default core.createStore(actions, {}, mutations, {
	key: 'orders',
	initial: 'idle',
	states: {
		idle: {
			entry: 'getPageData',
			on: {
				[types.APPLY_QUERY]: 'idle',
				[types.GET_PAGE_DATA_SUCCESS]: 'render'
			}
		},
		render: {
			on: {
				[types.APPLY_QUERY]: 'idle'
			},
			initial: 'main',
			states: {
				main: {
					on: {
						[types.OPEN_ORDER_SIDEBAR]: 'order_sidebar',
						[types.OPEN_ORDER_DETAILS_MODAL]: 'order_details_modal',
						[types.OPEN_OUTGOING_PRINT_ACTION_MODAL]: 'outgoing_print_action_modal',
						[types.OPEN_CONFIRM_CASH_FROM_CASHDESK_MODAL]: 'confirm_cash_from_cashdesk_order_modal',
						[types.OPEN_CONFIRM_CASH_FROM_SELF_SERVICE_TERMINAL_MODAL]:
							'confirm_cash_from_self_service_terminal_order_modal',
						[types.OPEN_PENDING_ORDERS_MODAL]: 'cashdesk_pending_orders_modal'
					}
				},
				order_sidebar: {
					on: {
						[types.CLOSE_ORDER_SIDEBAR]: 'main'
					}
				},
				order_details_modal: {
					on: {
						[types.CLOSE_ORDER_DETAILS_MODAL]: 'main',
						[types.OPEN_OUTGOING_PRINT_ACTION_MODAL]: 'outgoing_print_action_modal',
						[types.OPEN_ORDER_SIDEBAR]: 'order_sidebar'
					}
				},
				cashdesk_pending_orders_modal: {
					on: {
						[types.CLOSE_PENDING_ORDERS_MODAL]: 'main',
						[types.OPEN_PENDING_ORDERS_MODAL]: 'cashdesk_pending_orders_modal'
					}
				},
				outgoing_print_action_modal: {
					on: {
						[types.CLOSE_OUTGOING_PRINT_ACTION_MODAL]: 'main'
					}
				},
				confirm_cash_from_cashdesk_order_modal: {
					on: {
						[types.CLOSE_CONFIRM_CASH_FROM_CASHDESK_MODAL]: 'main',
						[types.OPEN_INVENTORY_ACT_MODAL]: 'inventory_act_modal',
						[types.CONFIRM_CASH_FROM_CASHDESK_SUCCESS]: 'main'
					}
				},
				confirm_cash_from_self_service_terminal_order_modal: {
					on: {
						[types.CLOSE_CONFIRM_CASH_FROM_SELF_SERVICE_TERMINAL_MODAL]: 'main',
						[types.OPEN_INVENTORY_ACT_MODAL]: 'inventory_act_modal',
						[types.CONFIRM_CASH_FROM_SELF_SERVICE_TERMINAL_SUCCESS]: 'main'
					}
				},
				inventory_act_modal: {
					on: {
						[types.CLOSE_INVENTORY_ACT_MODAL_CASHDESK]: 'confirm_cash_from_cashdesk_order_modal',
						[types.CLOSE_INVENTORY_ACT_MODAL_SST]: 'confirm_cash_from_self_service_terminal_order_modal',
						[types.CONFIRM_CASH_FROM_CASHDESK_SUCCESS]: 'main',
						[types.CONFIRM_CASH_FROM_SELF_SERVICE_TERMINAL_SUCCESS]: 'main'
					}
				}
			}
		}
	}
});
