import { core } from 'novapay-ui';

import actions from './actions';
import mutations from './mutations';

const types = core.createTypes('salepoints_access');

export default core.createStore(actions, {}, mutations, {
	key: 'salepoints_access',
	strict: true,
	initial: 'render',
	states: {
		isLoadingDiiaData: false,
		isLoadingChecking: false,
		idle: {
			entry: 'initModule',
			on: {
				[types.INIT_MODULE]: 'render',
				[types.OPEN_DIIA_BARCODE]: 'diia_barcode'
			}
		},
		diia_barcode: {
			on: {
				[types.CLOSE_DIIA_BARCODE]: 'render'
			}
		},
		render: {
			initial: 'main',
			on: {
				[types.IDLE]: 'idle'
			},
			states: {
				main: {
					on: {
						[types.OPEN_DIIA_BARCODE]: 'diia_barcode'
					}
				},
				diia_barcode: {
					on: {
						[types.CLOSE_DIIA_BARCODE]: 'main'
					}
				}
			}
		}
	}
});
