import { core, http, validation, lookup } from 'novapay-ui';
import print from '@services/print';
import find from 'lodash/find';
import BigNumber from 'bignumber.js';
import Promise from 'bluebird';
import { DateTime } from 'luxon';
import qs from 'qs';

import calculateCash from '@services/calculate-cash';
import commonStore from '@services/common-store';
import handleError from '@services/handle-api-error';
import router from '@/router';
import orderSidebarActions from '../components/order-sidebar/store/actions';

const types = core.getTypes('orders');
const rootTypes = core.getTypes('root');
const { actions: commonActions } = commonStore('orders');

import { enum as cashbookOrderSubTypes } from '@repo/enums/cashbook-order-sub-types';
import { enum as cashbookOrderStatuses } from '@repo/enums/cashbook-order-statuses';
import { enum as centrifugoEventTypes } from '@repo/enums/centrifugo-event-types';
import { enum as actTypes } from '@repo/enums/inventory-act-types';

const validateAmount = validation.compile({
	type: 'number',
	minimum: 0,
	multipleOf: 0.1,
	maximum: 99999999.99
});

const getCashbookToRender = (context) => {
	context.commit(types.SET_CASHBOOK_ID, router.currentRoute.params?.cashbook_id ?? null);
};

const getPageData = async (context) => {
	let { query, cashbook_id, cashbook } = context.state.props;

	if (cashbook_id && isNaN(parseInt(cashbook_id))) {
		return context.commit(rootTypes.namespaced.ERROR, { code: 404 }, { root: true });
	}

	if (!cashbook_id) {
		let orders = await http('/v3/orders', { query });
		if (!handleError()(orders, context)) {
			return;
		}
		let total = orders.data?.length && orders.data[0].total_count;
		context.commit(types.GET_PAGE_DATA_SUCCESS, { orders: orders.data, total });
	} else {
		if (!cashbook) {
			let cashbookRes = await http(`/v3/cashbooks`, { query: { filters: { id: [cashbook_id] } } });
			if (!handleError()(cashbookRes, context)) {
				return;
			}
			if (!cashbookRes.data?.length) {
				return context.commit(rootTypes.namespaced.ERROR, { code: 404 }, { root: true });
			}
			cashbook = cashbookRes.data[0];
		}
		let [orders, totalsRes] = await Promise.all([
			http(`/v3/orders`, { query: { ...query, filters: { ...query?.filters, cashbook_id: [cashbook_id] } } }),
			http(`/v3/salepoint/totals/${DateTime.fromISO(cashbook.created_at).toISODate()}`)
		]);
		if (!handleError()(orders, context) || !handleError()(totalsRes, context)) {
			return;
		}
		context.commit(types.GET_PAGE_DATA_SUCCESS, {
			orders: orders.data,
			cashbook,
			totals: totalsRes.data,
			total: (orders.data?.length && orders.data[0].total_count) || 0
		});
	}

	commonActions.hideRootSplashScreen(context);
};

const toggleOrderSidebar = (context, row) => {
	if (context.state.state_key.render === 'order_sidebar') {
		return context.commit(types.CLOSE_ORDER_SIDEBAR);
	}
	return context.commit(types.OPEN_ORDER_SIDEBAR, row);
};

const toggleOrderDetailsModal = (context, entity) => {
	if (context.state.state_key.render === 'order_details_modal') {
		return context.commit(types.CLOSE_ORDER_DETAILS_MODAL);
	}
	return context.commit(types.OPEN_ORDER_DETAILS_MODAL, entity);
};

const cancelOrder = async (context, { id: order_id }) => {
	let res = await http('/v3/orders/cancel', { data: { order_id }, method: 'POST' });
	if (!handleError()(res, context)) {
		return;
	}
	context.commit(types.CLOSE_ORDER_DETAILS_MODAL);
};

const toggleCashFromCashdeskModal = (context, order) => {
	if (context.state.state_key.render === 'confirm_cash_from_cashdesk_order_modal') {
		return context.commit(types.CLOSE_CONFIRM_CASH_FROM_CASHDESK_MODAL);
	}
	return context.commit(types.OPEN_CONFIRM_CASH_FROM_CASHDESK_MODAL, order);
};

const toggleCashFromSelfServiceTerminalModal = (context, order) => {
	if (context.state.state_key.render === 'confirm_cash_from_self_service_terminal_order_modal') {
		return context.commit(types.CLOSE_CONFIRM_CASH_FROM_SELF_SERVICE_TERMINAL_MODAL);
	}
	return context.commit(types.OPEN_CONFIRM_CASH_FROM_SELF_SERVICE_TERMINAL_MODAL, order);
};

const toggleInventoryActModal = (context, actData) => {
	if (context.state.state_key.render === 'inventory_act_modal') {
		switch (context.state.props.order?.sub_type) {
			case cashbookOrderSubTypes.cashFromCashdesk:
				return context.commit(types.CLOSE_INVENTORY_ACT_MODAL_CASHDESK);
			case cashbookOrderSubTypes.cashFromSelfServiceTerminal:
				return context.commit(types.CLOSE_INVENTORY_ACT_MODAL_SST);
			default:
				return;
		}
	}
	return context.commit(types.OPEN_INVENTORY_ACT_MODAL, actData);
};

const confirmCashFromCashdeskOrder = async (context, { order, act_data }) => {
	let res = await http('/v3/orders/cash-from-cashdesk', { data: { order_id: order.id, act_data }, method: 'POST' });
	if (!handleError()(res, context, 200, types.VALIDATION_ERRORS)) {
		return;
	}
	if (res.data.act?.id) {
		let actRes = await http(`/v3/salepoint/print-inventory-act/${res.data.act.id}`);
		if (!handleError()(actRes, context)) {
			return;
		}
		print(actRes.data);
	}
	context.commit(types.CONFIRM_CASH_FROM_CASHDESK_SUCCESS);
};

const confirmCashFromCashdeskOrderOrToggleInvActModal = (context, { order, cash }) => {
	let { opened_cashdesks, cashbook_shift } = context.rootState.root.props;
	let cashdesk = find(opened_cashdesks, { user_id: order?.participant_id });

	if (!cashdesk || !order) {
		return context.commit(types.CLOSE_CONFIRM_CASH_FROM_CASHDESK_MODAL);
	}

	let amount = calculateCash(cash);
	let { errors } = validateAmount(amount);

	if (errors) {
		return;
	}

	let { user_id, user_first_name, user_last_name, user_patronymic, user_position } = cashdesk;

	if (!new BigNumber(amount).isEqualTo(order.recorded_amount)) {
		if (new BigNumber(amount).eq(0) && !confirm('Ви впевнені, що фактична сума 0?')) {
			return;
		}
		return toggleInventoryActModal(context, {
			cash,
			type: actTypes.cashdesk,
			user_from_id: user_id,
			user_from_name: `${user_last_name} ${user_first_name} ${user_patronymic || ''}`,
			user_from_position: user_position,
			user_to_id: cashbook_shift.user_id,
			user_to_name: `${cashbook_shift.last_name} ${cashbook_shift.first_name} ${cashbook_shift.patronymic || ''}`,
			user_to_position: cashbook_shift.position
		});
	}

	return confirmCashFromCashdeskOrder(context, { order, act_data: { cash } });
};

const confirmCashFromSelfServiceTerminalOrder = async (context, { order, act_data }) => {
	let participant_user = null;
	if (order.participant_user) {
		let { last_name, first_name, patronymic } = order.participant_user;
		participant_user = `${last_name} ${first_name} ${patronymic || ''}`;
	}
	let res = await http('/v3/orders/cash-from-self-service-terminal', {
		data: {
			order_id: order.id,
			act_data: { ...act_data, participant_user, participant_user_id: order.participant_user?.id }
		},
		method: 'POST'
	});
	if (!handleError()(res, context, 200, types.VALIDATION_ERRORS)) {
		return;
	}
	if (res.data.act?.id) {
		let actRes = await http(`/v3/salepoint/print-inventory-act/${res.data.act.id}`);
		if (!handleError()(actRes, context)) {
			return;
		}
		print(actRes.data);
	}
	context.commit(types.CONFIRM_CASH_FROM_SELF_SERVICE_TERMINAL_SUCCESS);
};

const confirmCashFromSelfServiceTerminalOrderOrToggleInvActModal = (context, { order, cash, participant_user }) => {
	let { cashbook_shift } = context.rootState.root.props;

	if (!cashbook_shift || !order) {
		return context.commit(types.CONFIRM_CASH_FROM_SELF_SERVICE_TERMINAL_SUCCESS);
	}

	let amount = calculateCash(cash);
	let { errors } = validateAmount(amount);

	if (errors) {
		return;
	}

	context.commit(types.UPDATE_ORDER, { participant_user });

	let {
		id: user_id,
		first_name: user_first_name,
		last_name: user_last_name,
		patronymic: user_patronymic,
		position: user_position
	} = participant_user;

	if (!new BigNumber(amount).isEqualTo(order.recorded_amount)) {
		if (new BigNumber(amount).eq(0) && !confirm('Ви впевнені, що фактична сума 0?')) {
			return;
		}

		return toggleInventoryActModal(context, {
			cash,
			type: actTypes.sst,
			user_from_id: user_id,
			user_from_name: `${user_last_name} ${user_first_name} ${user_patronymic || ''}`,
			user_from_position: user_position,
			user_to_id: cashbook_shift.user_id,
			user_to_name: `${cashbook_shift.last_name} ${cashbook_shift.first_name} ${cashbook_shift.patronymic || ''}`,
			user_to_position: cashbook_shift.position,
			type_data: {
				sst: order.sst
			}
		});
	}

	return confirmCashFromSelfServiceTerminalOrder(context, {
		order: { ...order, participant_user },
		act_data: { cash }
	});
};

const confirmOrder = async (context, order) => {
	let { id, sub_type } = order;

	if (
		order &&
		order?.pendingOrders?.filter(
			(o) => o.participant_id === order.participant_id && o.sub_type === cashbookOrderSubTypes.cashToCourier
		)?.length
	) {
		return togglePendingOrdersModal(context, order);
	}

	let method;
	switch (sub_type) {
		case cashbookOrderSubTypes.cashToBank:
			method = () => http('/v3/orders/cash-to-bank', { data: { order_id: id }, method: 'POST' });
			break;
		case cashbookOrderSubTypes.encashmentByCashier:
			method = () => http('/v3/orders/encashment-by-cashier', { data: { order_id: id }, method: 'POST' });
			break;
		case cashbookOrderSubTypes.cashFromCashdesk:
			return toggleCashFromCashdeskModal(context, order);
		case cashbookOrderSubTypes.cashFromSelfServiceTerminal:
			return toggleCashFromSelfServiceTerminalModal(context, order);
		default:
			return;
	}

	let res = await method();
	if (!handleError()(res, context)) {
		return;
	}
	context.commit(types.CLOSE_ORDER_DETAILS_MODAL);
};

const confirmOrderWithAct = async (context, { order, act_data }) => {
	let { sub_type } = order;

	switch (sub_type) {
		case cashbookOrderSubTypes.cashFromCashdesk:
			await confirmCashFromCashdeskOrder(context, { order, act_data });
			return;
		case cashbookOrderSubTypes.cashFromSelfServiceTerminal:
			await confirmCashFromSelfServiceTerminalOrder(context, { order, act_data });
			return;
		default:
			return;
	}
};

const printOrder = async (context, order) => {
	let { id, status, sub_type } = order;
	let method;
	switch (sub_type) {
		case cashbookOrderSubTypes.cashToBank:
			if (status === cashbookOrderStatuses.created) {
				method = () => http(`/v3/orders/print-cover-sheet/${id}`);
			} else {
				return toggleOutgoingPrintActionModal(context, order);
			}
			break;
		case cashbookOrderSubTypes.encashmentByCashier:
			if (status === cashbookOrderStatuses.created) {
				method = () => http(`/v3/orders/print-encashment-by-cashier-cover-sheet/${id}`);
				break;
			} else {
				return toggleOutgoingPrintActionModal(context, order);
			}
		case cashbookOrderSubTypes.cashToAnotherSalepoint:
		case cashbookOrderSubTypes.cashToTransitionalSalepoint:
		case cashbookOrderSubTypes.cashToCentral:
		case cashbookOrderSubTypes.cashToCourier:
			return toggleOutgoingPrintActionModal(context, order);
		default:
			method = () => http(`/v3/orders/print/${id}`);
	}
	let res = await method();
	if (!handleError()(res, context)) {
		return;
	}
	await print(res.data);
};

const printOrders = async (context, { ordersIds }) => {
	let res = await http(`/v3/orders/print-orders?${qs.stringify({ ids: ordersIds })}`);
	if (!handleError()(res, context)) {
		return;
	}
	await print(res.data);
};

const printCashToBank = async (context, { id, type }) => {
	let url = type === 'cover-sheet' ? `/v3/orders/print-cover-sheet/${id}` : `/v3/orders/print/${id}`;
	let res = await http(url);
	if (!handleError()(res, context)) {
		return;
	}
	await print(res.data);
};

const printEncashmentByCashier = async (context, { id, type }) => {
	let url =
		type === 'cover-sheet' ? `/v3/orders/print-encashment-by-cashier-cover-sheet/${id}` : `/v3/orders/print/${id}`;
	let res = await http(url);
	if (!handleError()(res, context)) {
		return;
	}
	await print(res.data);
};

const printCashToAnotherSalepoint = async (context, { id, type }) => {
	let url =
		type === 'cover-sheet' ? `/v3/orders/print-cash-to-another-salepoint-cover-sheet/${id}` : `/v3/orders/print/${id}`;
	let res = await http(url);
	if (!handleError()(res, context)) {
		return;
	}
	await print(res.data);
};

const printCashToCentral = async (context, { id, type }) => {
	let url = type === 'cover-sheet' ? `/v3/orders/print-cash-to-central-cover-sheet/${id}` : `/v3/orders/print/${id}`;
	let res = await http(url);
	if (!handleError()(res, context)) {
		return;
	}
	await print(res.data);
};

const printCashToCourier = async (context, { id, type }) => {
	let url = type === 'cover-sheet' ? `/v3/orders/print-cash-to-courier-cover-sheet/${id}` : `/v3/orders/print/${id}`;
	let res = await http(url);
	if (!handleError()(res, context)) {
		return;
	}
	print(res.data);
};

const toggleOutgoingPrintActionModal = (context, order) => {
	if (context.state.state_key.render === 'outgoing_print_action_modal') {
		return context.commit(types.CLOSE_OUTGOING_PRINT_ACTION_MODAL);
	}
	return context.commit(types.OPEN_OUTGOING_PRINT_ACTION_MODAL, order);
};

const togglePendingOrdersModal = (context, order) => {
	if (context.state.state_key?.render === 'cashdesk_pending_orders_modal') {
		return context.commit(types.CLOSE_PENDING_ORDERS_MODAL);
	}
	return context.commit(types.OPEN_PENDING_ORDERS_MODAL, order);
};

const printCashbook = async (context) => {
	let { id } = context.state.props.cashbook;
	let res = await http(`/v3/cashbooks/print/${id}/all`);
	if (!handleError()(res, context)) {
		return;
	}
	await print(res.data);
};

const updateTotalsOnEvent = async (context) => {
	let { cashbook } = context.state.props;
	if (!cashbook) {
		return;
	}
	await Promise.delay(1000);
	let res = await http(`/v3/salepoint/totals/${DateTime.fromISO(cashbook.created_at).toISODate()}`);
	if (!handleError()(res, context)) {
		return;
	}
	context.commit(types.TOTALS_UPDATE, res.data);
};

const eventListener = (context, e) => {
	switch (e.type) {
		case centrifugoEventTypes.cashbook:
			updateTotalsOnEvent(context);
			return context.commit(types.CASHBOOK_EVENT, e.payload);
		case centrifugoEventTypes.cashbookOrder:
			return context.commit(types.CASHBOOK_ORDER_EVENT, e.payload);
		default:
			return;
	}
};

const lookupEncashmentParticipant = (context, { salepoint_id, cb }) =>
	lookup(`/v3/lookups/users/encashment-participant?salepoint_id=${salepoint_id || ''}`).then(
		(res) => handleError()(res, context, 200) && cb(res.data)
	);

export default {
	...commonActions,
	...orderSidebarActions,
	getCashbookToRender,
	getPageData,
	toggleOrderSidebar,
	toggleOrderDetailsModal,
	cancelOrder,
	confirmOrder,
	confirmOrderWithAct,
	printOrder,
	printOrders,
	printCashbook,
	printCashToBank,
	printCashToCourier,
	printEncashmentByCashier,
	printCashToAnotherSalepoint,
	printCashToCentral,
	toggleOutgoingPrintActionModal,
	eventListener,
	toggleCashFromCashdeskModal,
	confirmCashFromCashdeskOrderOrToggleInvActModal,
	confirmCashFromCashdeskOrder,
	toggleCashFromSelfServiceTerminalModal,
	confirmCashFromSelfServiceTerminalOrderOrToggleInvActModal,
	confirmCashFromSelfServiceTerminalOrder,
	toggleInventoryActModal,
	lookupEncashmentParticipant,
	togglePendingOrdersModal
};
